import { Box, Button, ClickAwayListener, FormControl, InputLabel, Popper, Select } from '@mui/material';
import React, { useEffect, useReducer } from 'react';
import { tss } from 'tss-react/mui';
import getMethodsBySubject from '../../../selectors/method';
import getModulesByMethod from '../../../selectors/module';
import {
  getCurrentHierarchyMethodId,
  getCurrentHierarchyModuleId,
  getCurrentHierarchySubjectId,
  getCurrentPublishingHouseId,
  getCurrentTocNodeId,
} from '../../../selectors/context';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getTocNodes } from '../../../selectors/table-of-content';
import { fetchModulesByMethod } from '../../../actions/module';
import { fetchTableOfContentFor } from '../../../actions/table-of-content';
import { CopyTarget } from '../../../api/slideSetVersion';
import getSubjectsByPublishingHouse from '../../../selectors/subject';
import { fetchMethodsBySubject } from '../../../actions/method';

const useStyle = tss.create({
  popper: {
    zIndex: 1000,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '1rem',
    width: '300px',
    border: '1px solid black',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
});

type ReducerAction = {
  type: 'SET_SELECTED_SUBJECT_ID' | 'SET_SELECTED_METHOD_ID' | 'SET_SELECTED_MODULE_ID' | 'SET_SELECTED_NODE_ID';
  selectedId: string;
};

type ReducerState = {
  selectedSubjectId: string;
  selectedMethodId?: string;
  selectedModuleId?: string;
  selectedNodeId?: string;
};

type Props = {
  isOpen?: boolean;
  anchorEl: HTMLElement | null;
  onConfirm: (target: CopyTarget) => void;
  onClose: () => void;
};

export default function CopyToSlideSetPopper({ isOpen = false, anchorEl, onClose, onConfirm }: Props) {
  const { classes } = useStyle();

  const currentPublishingHouseId = useAppSelector(getCurrentPublishingHouseId);
  const currentSubjectId = useAppSelector(getCurrentHierarchySubjectId);
  const currentMethodId = useAppSelector(getCurrentHierarchyMethodId);
  const currentModuleId = useAppSelector(getCurrentHierarchyModuleId);
  const currentNodeId = useAppSelector(getCurrentTocNodeId);

  const appDispatch = useAppDispatch();

  function reducer(state: ReducerState, action: ReducerAction): ReducerState {
    const { type, selectedId } = action;

    switch (type) {
      case 'SET_SELECTED_SUBJECT_ID':
        appDispatch(fetchMethodsBySubject(selectedId));
        return {
          selectedSubjectId: selectedId,
          selectedMethodId: undefined,
          selectedModuleId: undefined,
          selectedNodeId: undefined,
        };
      case 'SET_SELECTED_METHOD_ID':
        appDispatch(fetchModulesByMethod(selectedId));
        return {
          ...state,
          selectedMethodId: selectedId,
          selectedModuleId: undefined,
          selectedNodeId: undefined,
        };
      case 'SET_SELECTED_MODULE_ID':
        appDispatch(fetchTableOfContentFor(selectedId));
        return {
          ...state,
          selectedModuleId: selectedId,
          selectedNodeId: undefined,
        };
      case 'SET_SELECTED_NODE_ID':
        return {
          ...state,
          selectedNodeId: selectedId,
        };
      default:
        throw new Error('invalid action type');
    }
  }

  const [state, dispatch] = useReducer<typeof reducer>(reducer, {
    selectedSubjectId: currentSubjectId,
    selectedMethodId: currentMethodId,
    selectedModuleId: currentModuleId,
    selectedNodeId: currentNodeId,
  });

  // ignore because our redux store is not typed
  const subjects = useAppSelector(reduxState =>
    // @ts-ignore
    getSubjectsByPublishingHouse(reduxState, { publishingHouseId: currentPublishingHouseId }),
  );
  // @ts-ignore
  const methods = useAppSelector(reduxState => getMethodsBySubject(reduxState, { subjectId: state.selectedSubjectId }));
  // @ts-ignore
  const modules = useAppSelector(reduxState => getModulesByMethod(reduxState, { methodId: state.selectedMethodId }));
  const nodes = useAppSelector(reduxState =>
    // @ts-ignore
    state.selectedModuleId ? getTocNodes(reduxState, state.selectedModuleId) : [],
  );

  const handleChange = (type: ReducerAction['type'], selectedId: string) => {
    dispatch({ type, selectedId });
  };

  const canConfirm = Object.values(state).every(value => !!value);

  const handleConfirmClick = async () => {
    if (!canConfirm) return;

    const target = {
      methodId: state.selectedMethodId!,
      moduleId: state.selectedModuleId!,
      nodeId: state.selectedNodeId!,
    };

    onConfirm(target);
  };

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') onClose();
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper open={isOpen} anchorEl={anchorEl} className={classes.popper} placement="bottom-end">
        <Box className={classes.wrapper}>
          <FormControl variant="standard" margin="dense">
            <InputLabel htmlFor="copy-to-subject">Subject</InputLabel>
            <Select
              id="copy-to-subject"
              variant="standard"
              native
              value={state.selectedSubjectId}
              onChange={e => handleChange('SET_SELECTED_SUBJECT_ID', e.target.value)}
            >
              {subjects.map(subject => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" margin="dense">
            <InputLabel htmlFor="copy-to-method">Method</InputLabel>
            <Select
              id="copy-to-method"
              variant="standard"
              native
              value={state.selectedMethodId}
              onChange={e => handleChange('SET_SELECTED_METHOD_ID', e.target.value)}
            >
              <option value="" aria-label="empty option method target" hidden />
              {methods.map(method => (
                <option key={method.id} value={method.id}>
                  {method.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" margin="dense">
            <InputLabel htmlFor="copy-to-module">Module</InputLabel>
            <Select
              id="copy-to-module"
              variant="standard"
              native
              value={state.selectedModuleId || ''}
              onChange={e => handleChange('SET_SELECTED_MODULE_ID', e.target.value)}
              label="Module"
            >
              <option value="" aria-label="empty option module target" hidden />
              {modules.map(module => (
                <option key={module.id} value={module.id}>
                  {module.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" margin="dense">
            <InputLabel htmlFor="copy-to-node">Node</InputLabel>
            <Select
              id="copy-to-node"
              variant="standard"
              value={state.selectedNodeId || ''}
              onChange={e => handleChange('SET_SELECTED_NODE_ID', e.target.value)}
              label="Node"
              native
            >
              <option value="" aria-label="empty option TOC node target" hidden />
              {nodes?.map(node => (
                <option key={`module-${state.selectedModuleId}-node-${node.id}`} value={node.id}>
                  {node.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} variant="contained" disabled={!canConfirm}>
            Copy slide set
          </Button>
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
